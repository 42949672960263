<template>
    <el-dialog
      :append-to-body="true"
      :visible.sync="dialog"
      width="600px"
      title="邮箱配置"
    >
    <el-form ref="form" :model="form" :rules="rules" style="margin-top: 6px;" size="small" label-width="100px">
      <el-form-item label="邮箱类型" prop="type">
        <el-select v-model="form.type" clearable placeholder="请选择邮箱类型" class="filter-item" style="width: 130px">
        <el-option v-for="item in emailTypes"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"/>
      </el-select>
      </el-form-item>
      <el-form-item label="发件人邮箱" prop="fromUser">
        <el-input v-model="form.fromUser" style="width: 40%"/>
        <!-- <span style="color: #C0C0C0;margin-left: 10px;">发件邮箱地址</span> -->
      </el-form-item>
      <el-form-item label="发件用户名" prop="user">
        <el-input v-model="form.user" style="width: 40%;"/>
        <!-- <span style="color: #C0C0C0;margin-left: 10px;">发件用户名</span> -->
      </el-form-item>
      <el-form-item label="邮箱密码" prop="pass">
        <el-input v-model="form.pass"  style="width: 40%;"/>
        <!-- <span style="color: #C0C0C0;margin-left: 10px;">邮箱密码</span> -->
      </el-form-item>
      <el-form-item label="SMTP地址" prop="host">
        <el-input v-model="form.host" style="width: 40%;"/>
        <!-- <span style="color: #C0C0C0;margin-left: 10px;">邮件服务器的SMTP地址</span> -->
      </el-form-item>
      <el-form-item label="SMTP端口" prop="port">
        <el-input v-model="form.port" style="width: 40%;"/>
        <!-- <span style="color: #C0C0C0;margin-left: 10px;">邮件服务器的SMTP端口</span> -->
      </el-form-item>
      <el-form-item label="是否开启ssl" prop="ssl">
        <el-radio v-model="form.ssl" label="true">是</el-radio>
        <el-radio v-model="form.ssl" label="false">否</el-radio>
        <!-- <span style="color: #C0C0C0;margin-left: 10px;">是否开启ssl，默认开启</span> -->
      </el-form-item>
       <el-button :loading="loading" style="margin-right:10px;" size="medium" type="success" @click="doSubmit">保存配置</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import { get, configEmail } from '@/api/tools/email'
export default {
  name: 'Config',
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false, dialog: false,form: { type:0,siteId:'',protocol:'smtp',ssl:'true',fromUser: '', user: '', pass: '', host: '', port: '', sslEnable: '' },
      rules: {
        fromUser: [
          { required: true, message: '请输入发件人邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        ],
        user: [
          { required: true, message: '请输入发件用户名', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        host: [
          { required: true, message: 'SMTP地址不能为空', trigger: 'blur' }
        ],
        port: [
          { required: true, message: 'SMTP端口不能为空', trigger: 'blur' }
        ],
        ssl: [
          { required: true, message: '选择是否开启ssl', trigger: 'blur' }
        ]
      },
      emailTypes:[
        {key:0,display_name:'默认邮箱'},
        {key:1,display_name:'验证码邮箱'},
        {key:2,display_name:'通知邮箱'}
      ]
    }
  },
  methods: {
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.form.siteId =this.siteId
          configEmail(this.form).then(res => {
            this.$notify({
              title: '修改成功',
              type: 'success',
              duration: 2500
            });
            this.loading = false
            this.dialog=false
            this.form = { type:0,siteId:'',protocol:'smtp',ssl:'true',fromUser: '', user: '', pass: '', host: '', port: '', sslEnable: '' }
            this.$emit('refresh-list');
          }).catch(err => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
